import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import "./Footer.scss";
export default function Footer() {
    // Array of social media elements with name, href, and icon properties
    const socialMediaElements = [
        { name: "Facebook", href: "https://www.facebook.com/EzerDaru", icon: "fa-brands fa-facebook" },
        { name: "Instagram", href: "https://www.instagram.com/ezerdaru/", icon: "fa-brands fa-instagram" },
    ];
    return (_jsxs("div", { className: "footer container-fluid", children: [_jsx("div", { className: "socialMedia", children: socialMediaElements.map(element => (_jsx(Link, { className: "icons", to: element.href, target: "_blank", rel: "noreferrer", children: _jsx("i", { className: `${element.icon}` }) }, element.name))) }), _jsx("p", { children: "Ezer Daru Adom\u00E1nybolt \u00A9 2023" })] }));
}
